import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Admin/Citas_Admin.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/AdminDoctores/check_session_admin_doctor_otros.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/AdminDoctor');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


//Conexión a Base de datos
const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    // Realizar la solicitud al backend para obtener los datos del usuario
    axios.get('https://medico24x7.com/api/AdminDoctores/citas_admin_doctor.php', { withCredentials: true }) // Cambia la ruta a tu API
      .then(response => {
        //console.log(response.data);
        setUsuario(response.data); // Supongo que devuelve un array de usuarios
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, []);

 //Codigo para cerrar sesión
 const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/AdminDoctor'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div>
      <main id='usuario_citas'>
      <div className="info_table_citas">
        <table className='table_citas'>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Correo Electrónico</th>
              <th>Celular</th>
              <th>Fecha Preferida</th>
              <th>Hora Preferida</th>
              <th>Tipo de Servicio</th>
              <th>Motivo de la Cita</th>
            </tr>
          </thead>
          <tbody>
            {usuario.length > 0 ? (
              usuario.map((user, index) => (
                <tr key={index}>
                  <td>{user.nombre}</td>
                  <td>{user.apellido}</td>
                  <td>{user.correo}</td>
                  <td>{user.telefono}</td>
                  <td>{user.fecha}</td>
                  <td>{user.hora}</td>
                  <td>{user.servicio}</td>
                  <td>{user.mensaje}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No hay datos disponibles</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </main>
    </div>
  );
}

export default App;