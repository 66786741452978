import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Usuarios/Consultas.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);  // Estado de carga
  const [error, setError] = useState(null);  // Estado de error

  useEffect(() => {
    // Llamada a la API de PHP para obtener los datos de la consulta
    axios.get('https://medico24x7.com/api/Usuarios/Consulta.php', { withCredentials: true })
      .then(response => {
        if (response.data.error) {
          setError(response.data.error);  // Si hay error en la respuesta
        } else {
          setUsuarios(response.data);  // Setea los datos de los usuarios
          //console.log(response.data);
        }
        setLoading(false);  // Desactiva el estado de carga
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        setError('Error al obtener los datos');
        setLoading(false);  // Desactiva el estado de carga si hay error
      });
  }, []);



   
  const handleDownload = async (id) => {
            // Llama al script PHP directamente para forzar la descarga
            const url = `https://medico24x7.com/api/Usuarios/descargar_archivo.php?id=${id}`;
            // Crea un enlace temporal y simula un clic
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
            document.body.appendChild(a);
            a.click(); // Simula el clic en el enlace
            document.body.removeChild(a); // Elimina el enlace
        };
  


//Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='body_usuario_consulta'>

      <main id='consulta_usuario'>
      <div className="info_table_consulta">
        {loading ? (
          <p>Cargando...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
            <>
          <table className='table_consultas'>
            <thead className='thead_consultas'>
              <tr className='tr_consultas'>
                <th className='th_consultas'>Nombre</th>
                <th className='th_consultas'>Apellido</th>
                <th className='th_consultas'>Nacionalidad</th>
                <th className='th_consultas'>Cédula</th>
                <th className='th_consultas'>Género</th>
                <th className='th_consultas'>Edad</th>
                <th className='th_consultas'>Dirección</th>
                <th className='th_consultas'>Síntomas</th>
                <th className='th_consultas'>Antecedentes Familiares</th>
                <th className='th_consultas'>Antecedentes Personales</th>
                <th className='th_consultas'>Antecedentes Quirúrgicos</th>
                <th className='th_consultas'>Alergias</th>
                <th className='th_consultas'>Otros Datos</th>
                <th className='th_consultas'>Correo</th>
                <th className='th_consultas'>Celular</th>
                <th className='th_consultas'>Analisis</th>
                <th className='th_consultas'>Plan</th>
                <th className='th_consultas'>Fecha de la Proxima Consulta</th>
                <th className='th_consultas'>Hora de la Proxima Consulta</th>
                <th className='th_consultas'>Archivo Exámenes</th>
              </tr>
            </thead>
            <tbody className='tbody_consultas'>
            {usuarios.length > 0 ? (
              usuarios.map((usuario, index) => (
                <tr key={index} className='tr_consultas'>
                  <td className='td_consultas'>{usuario.nombre}</td>
                  <td className='td_consultas'>{usuario.apellido}</td>
                  <td className='td_consultas'>{usuario.nacionalidad}</td>
                  <td className='td_consultas'>{usuario.cedula}</td>
                  <td className='td_consultas'>{usuario.genero}</td>
                  <td className='td_consultas'>{usuario.edad}</td>
                  <td className='td_consultas'>{usuario.direccion}</td>
                  <td className='td_consultas'>{usuario.sintomas}</td>
                  <td className='td_consultas'>{usuario.antecedentes_familiares}</td>
                  <td className='td_consultas'>{usuario.antecedentes_personales}</td>
                  <td className='td_consultas'>{usuario.antecedentes_quirurgicos}</td>
                  <td className='td_consultas'>{usuario.alergias}</td>
                  <td className='td_consultas'>{usuario.otros_datos}</td>
                  <td className='td_consultas'>{usuario.correo}</td>
                  <td className='td_consultas'>{usuario.celular}</td>
                  <td className='td_consultas'>{usuario.analisis}</td>
                  <td className='td_consultas'>{usuario.plan}</td>
                  <td className='td_consultas'>{usuario.fecha}</td>
                  <td className='td_consultas'>{usuario.hora}</td>
                  <td className='td_consultas'>
  {usuario.archivo_examenes_encrypted ? (
    <button onClick={() => handleDownload(usuario.id)}>
      Descargar
    </button>
  ) : (
    'No disponible'
  )}
</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No hay datos disponibles</td>
              </tr>
            )}
            </tbody>
          </table>
          </>
        )}
      </div>
      </main>
    </div>
  );
}

export default App;