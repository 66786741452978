import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/AdminDoctores/Consultas_Admin_Doctor.css';

const Consultas_Admin = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      // Verificar si el usuario está autenticado al cargar la aplicación
      axios.get('https://medico24x7.com/api/AdminDoctores/check_session_admin_doctor_otros.php', { withCredentials: true })
        .then(response => {
          if (response.data.authenticated) {
            setIsAuthenticated(true);
          } else {
            console.log('No autenticado');
            alert('Inicie sesión para acceder al área de consultas virtuales');
            navigate('/AdminDoctor');// Ajusta la ruta según tu configuración
          }
        })
        .catch(error => {
          console.error('Error al verificar la sesión:', error);
        });
    }, [navigate]);

  const [consultas, setConsultas] = useState([]);

  // Cargar las consultas desde la base de datos cuando se monta el componente
  useEffect(() => {
    axios.get('https://medico24x7.com/api/AdminDoctores/Consulta_Admin_Doctor.php', { withCredentials: true })
      .then(response => {
        setConsultas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las consultas:', error);
      });
  }, []);

  // Función para actualizar una consulta
  const actualizarConsulta = (consultaId) => {
    const analisis = document.getElementById(`analisis_${consultaId}`).value;
    const plan = document.getElementById(`plan_${consultaId}`).value;
    const fecha = document.getElementById(`fecha_${consultaId}`).value;
    const hora = document.getElementById(`hora_${consultaId}`).value;
    const revisado = document.getElementById(`revisado_${consultaId}`).checked ? 1 : 0;

    const consulta = consultas.find(c => c.id === consultaId);
    const correo = consulta.correo;

    const formData = new FormData();
    formData.append('consulta_id', consultaId);
    formData.append('correo', correo);
    formData.append('analisis', analisis);
    formData.append('plan', plan);
    formData.append('fecha', fecha);
    formData.append('hora', hora);
    formData.append('revisado', revisado);

    axios.post('https://medico24x7.com/api/AdminDoctores/actualizar_consulta_doctor.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          alert('Consulta actualizada con éxito');
        } else {
          alert('Error al actualizar la consulta: ' + response.data.message);
          console.log(response.data);
        }
      })
      .catch(error => {
        console.error('Error al actualizar la consulta:', error);
        alert('Ocurrió un error al actualizar la consulta.');
      });
  };

  const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/AdminDoctores/descargar_archivo_consulta.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
};



//Codigo para cerrar sesión
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/AdminDoctor'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

if (!isAuthenticated) {
return null; // O un loader
}

  return (
    <div className='consultas_admin_doctor'>
    <main className='Consultas_admin_main_doctor'>
      <table className='consultas_table_doctor'>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Nacionalidad</th>
            <th>Cédula</th>
            <th>Género</th>
            <th>Edad</th>
            <th>Dirección</th>
            <th>Tipo de Consulta</th>
            <th>Síntomas</th>
            <th>Antecedentes Familiares</th>
            <th>Antecedentes Personales</th>
            <th>Antecedentes Quirúrgicos</th>
            <th>Alergias</th>
            <th>Otros Datos</th>
            <th>Correo</th>
            <th>Celular</th>
            <th>Análisis</th>
            <th>Plan</th>
            <th>Fecha de la Próxima Consulta</th>
            <th>Hora de la Próxima Consulta</th>
            <th>Archivo Exámenes</th>
            <th>Revisado</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {
          consultas.length > 0 ? (
          consultas.map((consulta) => (
            <tr key={consulta.id}>
              <td>{consulta.nombre}</td>
              <td>{consulta.apellido}</td>
              <td>{consulta.nacionalidad}</td>
              <td>{consulta.cedula}</td>
              <td>{consulta.genero}</td>
              <td>{consulta.edad}</td>
              <td>{consulta.direccion}</td>
              <td>{consulta.tipo_de_consulta}</td>
              <td>{consulta.sintomas}</td>
              <td>{consulta.antecedentes_familiares}</td>
              <td>{consulta.antecedentes_personales}</td>
              <td>{consulta.antecedentes_quirurgicos}</td>
              <td>{consulta.alergias}</td>
              <td>{consulta.otros_datos}</td>
              <td id={`correo_${consulta.id}`} defaultValue={consulta.correo}>{consulta.correo}</td>
              <td>{consulta.celular}</td>
              <td>
                <textarea id={`analisis_${consulta.id}`} defaultValue={consulta.analisis}></textarea>
              </td>
              <td>
                <textarea id={`plan_${consulta.id}`} defaultValue={consulta.plan}></textarea>
              </td>
              <td>
                <input type="date" id={`fecha_${consulta.id}`} defaultValue={consulta.fecha} />
              </td>
              <td>
                <input type="time" id={`hora_${consulta.id}`} defaultValue={consulta.hora} />
              </td>
              <td>
              {consulta.archivo_examenes_encrypted ? (
    <button onClick={() => handleDownload(consulta.id)}>
      Descargar
    </button>
  ) : (
    'No disponible'
  )}
              </td>
              <td>
                <input type="checkbox" id={`revisado_${consulta.id}`} defaultChecked={consulta.revisado} />
              </td>
              <td>
                <button type="button" onClick={() => actualizarConsulta(consulta.id)}>Actualizar</button>
              </td>
            </tr>
          ))) : (
            <tr>
              <td colSpan="8">No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
    </main>
    </div>
  );
};

export default Consultas_Admin;