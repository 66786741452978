import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Admin/Recibo_Admin.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [consultas, setConsultas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://medico24x7.com/api/Admin/check_session_admin_otros.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/Admin');
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

  useEffect(() => {
    const fetchConsultas = async () => {
      try {
        const response = await axios.get("https://medico24x7.com/api/Admin/recibo_admin.php", { withCredentials: true });
        setConsultas(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error("Error al obtener las consultas:", error);
      }
    };

    fetchConsultas();
  }, []);

  const handleDownload = async (id) => {
    const url = `https://medico24x7.com/api/Admin/descargar_recibo.php?id=${id}`;
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleActualizarFila = async (consulta) => {
    const formData = new FormData();
  
    // Recopilar los datos necesarios
    formData.append('id', consulta.id);
    formData.append('revisado', consulta.revisado ? 1 : 0);
    if (consulta.archivo) {
      formData.append('archivo', consulta.archivo);
    }

    console.log('Valores enviados:', {
      id: consulta.id,
      revisado: consulta.revisado ? 1 : 0,
      archivo: consulta.archivo
    });
  
    try {
      const response = await axios.post('https://medico24x7.com/api/Admin/actualizar_pagos.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });
  
      if (response.status === 200) {
        alert(response.data.message);
        console.log(response.data)
      } else {
        alert("Error al actualizar la fila.");
      }
    } catch (error) {
      console.error("Error en la actualización:", error);
      alert("Error de conexión al actualizar la fila.");
    }
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    setConsultas(prevConsultas => {
      const updatedConsultas = [...prevConsultas];
      updatedConsultas[index] = { ...updatedConsultas[index], archivo: file };
      return updatedConsultas;
    });
  };

  const handleDescargarArchivo = (id) => {
    const url = `https://medico24x7.com/api/Admin/descargar_archivo_fiscal.php?id=${id}`;
    window.open(url, '_blank');
  };

   //Codigo para cerrar sesión
const cerrarSesion = useCallback( async () => {
  try {
  const response = await fetch('https://medico24x7.com/api/Admin/logout_admin.php', {
  method: 'POST',
  credentials: 'include', // Incluir cookies
  headers: {
    'Content-Type': 'application/json',
  },
  });
  
  const data = await response.json();
  
  if (data.success) {
  alert('La sesión ha expirado.');
  navigate('/Admin'); // Redirigir al login
  } else {
  alert('Error al cerrar la sesión');
  }
  } catch (error) {
  console.error('Error al cerrar la sesión:', error);
  }
  }, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; 
  }

  return (
    <div className='body_r_pagos'>
        <main id='main_recibo_admin'>
      <table className='recibo_table'>
        <thead>
          <tr>
            <th>Revisado</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Cedula</th>
            <th>Correo Electrónico</th>
            <th>Celular</th>
            <th>Monto</th>
            <th>Acuerdo</th>
            <th>Metodo De Pago</th>
            <th>Descargar Comprobante</th>
            <th>Subir Recibo Fiscal (PDF)</th>
            <th>Recibo Fiscal</th>
            <th>Token</th>
            <th>Actualizar Datos</th>
          </tr>
        </thead>
        <tbody>
  {Array.isArray(consultas) && consultas.length > 0 ? (
    consultas.map((consulta, index) => (
      <tr key={index}>
        <td>
          <input 
          type="checkbox" 
          name="revisado[]" 
          value={consulta.id} 
          checked={consulta.revisado} 
          onChange={() => setConsultas(prev => {
            const updated = [...prev];
            updated[index] = { ...updated[index], revisado: !consulta.revisado };
            return updated;
          })}
          />
        </td>
        <td>{consulta.nombre}</td>
        <td>{consulta.apellido}</td>
        <td>{consulta.cedula}</td>
        <td>{consulta.correo}</td>
        <td>{consulta.celular}</td>
        <td>{consulta.monto}</td>
        <td>{consulta.acuerdo}</td>
        <td>{consulta.metodo_de_pago}</td>
        <td>
          {consulta.comprobante_tranferencia_encrypted ? (
            <div className='descarga'>
            <button onClick={() => handleDownload(consulta.id)}>
              Descargar
            </button>
            <img className='img_recibo'
              src={`data:image/png;base64,${consulta.comprobante_tranferencia_encrypted}`} 
              alt="Comprobante de Pago" 
              style={{ width: '150px', height: 'auto' }} 
            />
          </div>
          ) : (
            "No disponible"
          )}
        </td>
        <td>
          <input type="file" onChange={(e) => handleFileChange(e, index)} />
        </td>
        <td>
          {consulta.archivo_nombre ? (
            <button onClick={() => handleDescargarArchivo(consulta.id)}>
              Descargar Archivo
            </button>
            ) : (
              "No disponible"
            )}
        </td>
        <td>{consulta.token}</td>
        <td>
          <button onClick={() => handleActualizarFila(consulta)}>
            Actualizar
          </button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="12">No hay recibos disponibles</td>
    </tr>
  )}
</tbody>
      </table>
    </main>
    </div>
  );
}

export default App;