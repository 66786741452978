import React, { useState, useEffect, useRef, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Citas.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

 // Estados para manejar los datos del formulario
 const [servicio, setServicio] = useState('');
 const [correo_doctor, setCorreo_Doctor] = useState('');
 const [mensaje, setMensaje] = useState('');
  const [doctores, setDoctores] = useState([]); // Estado para la lista de doctores
  const [doctor, setDoctor] = useState(""); // Estado para el doctor seleccionado

  // Función para manejar el cambio de servicio
  const handleServicioChange = async (e) => {
    const selectedServicio = e.target.value;
    setServicio(selectedServicio);

    // Hacer solicitud al backend para obtener los doctores que brindan el servicio seleccionado
    try {
      const response = await axios.get(`https://medico24x7.com/api/getDoctores.php?servicio=${selectedServicio}`, { withCredentials: true });
      setDoctores(response.data); // Actualizar la lista de doctores con la respuesta
      setDoctor(""); // Limpiar el doctor seleccionado
    } catch (error) {
      console.error("Error al obtener los doctores:", error);
    }
  };

//obtener horas disponibles
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableHours, setAvailableHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');

  // Obtener horas disponibles cuando se selecciona una fecha
  useEffect(() => {
    if (selectedDate) {
      const fetchAvailableHours = async () => {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        const formattedCorreo = correo_doctor;
        
        try {
          const response = await axios.get(
            `https://medico24x7.com/api/obtener_horas_disponibles.php?fecha=${formattedDate}&correo=${formattedCorreo}`
          );
          setAvailableHours(response.data);
          console.log(formattedCorreo);
        } catch (error) {
          console.error('Error al obtener las horas disponibles', error);
          console.log(formattedCorreo);
        }
      };

      fetchAvailableHours();
    }
  }, [selectedDate, correo_doctor]);

  const handleHourSelect = (e) => {
    setSelectedHour(e.target.value);
  };


  //obtener fechas disponibles
  const [availableFecha, setAvailableFecha] = useState([]);

  // Obtener horas disponibles cuando se selecciona una fecha
  useEffect(() => {
    if (correo_doctor) {
      const fetchAvailableFecha = async () => {
        const formattedCorreo = correo_doctor;
        
        try {
          const response = await axios.get(
            `https://medico24x7.com/api/obtener_fechas_disponibles.php?correo=${formattedCorreo}`
          );
          setAvailableFecha(response.data);
          console.log(formattedCorreo);
        } catch (error) {
          console.error('Error al obtener las horas disponibles', error);
          console.log(formattedCorreo);
        }
      };

      fetchAvailableFecha();
    }
  }, [correo_doctor]);

  // Función para verificar si una fecha está disponible
  const isDateUnavailable = (date) => {
    const dateString = date.toISOString().split('T')[0]; // Convierte a string 'YYYY-MM-DD'
    return availableFecha.includes(dateString);
  };
  

  // Función para validar y enviar los datos del formulario
  const enviarDatos = async () => {
    if (!servicio || !mensaje) {
      alert('Por favor, complete todos los campos obligatorios.');
      return; // Detener si la validación falla
    }
  
    try {
      // Crear el objeto con los datos
      const formData = new FormData();
      formData.append('servicio', servicio);
      formData.append('mensaje', mensaje);
      formData.append('fecha', selectedDate.toISOString().split('T')[0]); // Añadir la fecha
      formData.append('hora', selectedHour); // Añadir la hora
      formData.append('correo_doctor', correo_doctor);
  
      // Enviar los datos al servidor usando axios
      const response = await axios.post('https://medico24x7.com/api/guardar_cita.php', 
        formData, {
          withCredentials: true 
        });
  
      // Procesar la respuesta del servidor
      if (response.data.success) {
        alert('Su solicitud de cita ha sido enviada correctamente.');
        // Limpiar el formulario
        setServicio('');
        setMensaje('');
        setDoctores('');
        setSelectedDate('');
        setSelectedHour('');
      } else {
        alert('Error al enviar su solicitud de cita: ' + response.data.message);
      }
    } catch (error) {
      alert('Ocurrió un error al enviar la consulta: ' + error.message);
    }
  };

//Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

if (!isAuthenticated) {
  return null;
}

  return (
    <div className='body_citas'>
      <>

      <main id='citas'>

      {/* Información de contacto y mapa */}
      <div className="consulta-virtual">
        <form className='formularioPresencial'>
          <fieldset>
            <h2>Información</h2>
            {/* <p><strong>Precio de la Cita:</strong> $10/hora</p> */}
            <fieldset>
            <div id='info-citas'>
            <p id='p-consultas'>"En esta página puedes reservar una cita para una consulta presencial en la clínica. Si necesitas una consulta médica de manera inmediata, también ofrecemos "Consultas Virtuales" para tu comodidad."</p>
            </div>
            </fieldset>
            <p><strong>Horario de citas:</strong> Lunes - Viernes de 8:00 a.m. - 10:00 p.m. / Sábado y Domingo de 9:00 a.m. - 5:00 p.m.</p>
            <p><strong>Número de Celular:</strong> +507 6475-3624 (solo para mensajes de WhatsApp)</p>
            <p><strong>Correo:</strong> <a href="mailto:consultame@medico24x7.com">consultame@medico24x7.com</a></p>
            <p><strong>Ubicación:</strong> Panamá, Provincia: Coclé, Distrito: Penonomé, Quintas El Encanto, Casa 19A</p>
          </fieldset>
          <div className="mapa">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.7909084430216!2d-80.34508605888207!3d8.519671051510636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fac4fb708547bb9%3A0x8929096f32620ac8!2smedico24x7!5e0!3m2!1ses-419!2spa!4v1724913683959!5m2!1ses-419!2spa"
              width="450"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Mapa de Medico24x7" // Aquí agregamos el atributo title
            ></iframe>
          </div>
        </form>
      </div>

      {/* Formulario de solicitud de cita */}
      <div className="cont-formulario">
        <h1 className="form-title">Solicitud Para Consulta Presencial</h1>
        <form className="formularioPresencial">
          {/* Campo para seleccionar el tipo de servicio */}
          <fieldset>
          <div className="form-group">
        <label htmlFor="servicio">Tipo de Servicio:</label>
        <select id="servicio" name="servicio" value={servicio} onChange={handleServicioChange} required>
          <option value="" disabled>
            Seleccione el servicio
          </option>
          <option value="consulta_general">Medicina General</option>
          <option value="psicologia">Psicología</option>
          <option value="medicina_interna">Medicina Interna</option>
        </select>
      </div>

      {doctores.length > 0 && (
        <div className="form-group">
          <label htmlFor="doctor">Seleccione el Doctor:</label>
          <select id="doctor" name="doctor" value={doctor} onChange={(e) => {setDoctor(e.target.value);
          
          // Busca el doctor seleccionado en la lista de doctores usando el correo
          const selectedDoctor = doctores.find((doc) => doc.correo === e.target.value);
          
          // Si el doctor es encontrado, actualiza el estado de correo_doctor
          if (selectedDoctor) {
            setCorreo_Doctor(selectedDoctor.correo);
          }}} required>
            <option value="" disabled>
              Seleccione el doctor
            </option>
            {doctores.map((doc) => (
              <option key={doc.id} value={doc.correo}>
                {doc.nombre}
              </option>
            ))}
          </select>
        </div>
      )}

 {/* Selección de fecha */}
{doctor && (
        <div className="form-group">
          <label>Selecciona una Fecha</label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
            filterDate={(date) => !isDateUnavailable(date)} // Filtrar fechas no disponibles
          />
        </div>
      )}

        {/* Selección de hora */}
        {selectedDate && availableHours.length > 0 && (
          <div className="form-group">
            <label>Selecciona una Hora</label>
            <select value={selectedHour} onChange={handleHourSelect}>
              <option value="" disabled>
                Selecciona una hora
              </option>
              {availableHours.map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </div>
        )}

            {/* Campo para el motivo de la cita */}
            <div className="form-group">
              <label htmlFor="mensaje">Motivo de la Consulta:</label>
              <textarea
                id="mensaje"
                name="mensaje"
                rows="4"
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                placeholder="Describa brevemente el motivo de la cita..."
                required
              ></textarea>
            </div>

            {/* Botón para enviar el formulario */}
            <div className="form-group">
              <button type="button" className="submit-button-citas  " onClick={enviarDatos}>
                Solicitar Consulta Presencial
              </button>
            </div>
          </fieldset>
        </form>
      </div>
      </main>
      </>
    </div>
  );
}

export default App;