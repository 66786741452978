import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Pagos.css'; //  estilos CSS 

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAutorizacionPago, setIsAutorizacionPago] = useState(false);
  const [isMensajeCunsulta, setIsMensajeCunsulta] = useState(false);
  const [isMensajePago, setIsMensajePago] = useState('');
  const [mostrarForm2, setMostrarForm2] = useState(false);
  const [metodoPago, setMetodoPago] = useState('');
  const [paypalClientId, setPaypalClientId] = useState('');
  const [loadingPaypal, setLoadingPaypal] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const navigate = useNavigate();
  const [Value, setValue] = useState('');

  const [checkboxVisible, setCheckboxVisible] = useState(true);

  

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
          setValue(response.data.precio);
          //console.log(response.data.precio);
          //console.log(Value);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


  //Codigo para permitir pagos
  useEffect(() => {
    const validar_autorizacion_pago = async () => {
    // Verificar si el usuario está autorizado para realizar el pago
    axios.get('https://medico24x7.com/api/check_autorizacion_pago.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          if (["tipo_a", "tipo_b", "tipo_c"].includes(response.data.tipo)) {
            if (["tipo_a", "tipo_b"].includes(response.data.tipo)){
              setIsAutorizacionPago(true);
              setIsMensajeCunsulta("Ya Puede Realizar El Pago")
            } else{
              setIsAutorizacionPago(false);
              setIsMensajePago("No se puede pagar");
              setIsMensajeCunsulta("Su consulta es de tipo C, por lo cual no podemos atenderlo. Diríjase al hospital o clínica más cercana.")}
        }else {
            setIsAutorizacionPago(false);
            setIsMensajePago("No se le ha dado autorización");
            setIsMensajeCunsulta("Tipo de consulta");
        }
        } else {
          //console.log('No autorizado');
          setIsAutorizacionPago(false);
          setIsMensajePago("");
          setIsMensajeCunsulta("Espere hasta que se le asigne un tipo de consulta");
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
    }

    // Ejecutar la validación inicialmente
    validar_autorizacion_pago();

    // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
    const intervalIdPago = setInterval(() => {
      validar_autorizacion_pago();
    }, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalIdPago);
  }, []);


  //Codigo para permitir consulta
  useEffect(() => {
    const validar_autorizacion_consulta = async () => {
    // Verificar si el usuario está autorizado para realizar el pago
    axios.get('https://medico24x7.com/api/check_autorizacion_consulta.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          navigate('/ConsultaChat');
        } 
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
    }

    // Ejecutar la validación inicialmente
    validar_autorizacion_consulta();

    // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
    const intervalIdConsulta = setInterval(() => {
      validar_autorizacion_consulta();
    }, 5000); // 5000 milisegundos = 5 segundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalIdConsulta);
  }, [navigate]);


  useEffect(() => {
    if (metodoPago === 'paypal' && paypalClientId) {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=USD`;
      script.async = true;
      script.onload = () => {
        window.paypal.Buttons({
          createOrder: async (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: Value // Cambia por el valor real
                }
              }]
            });
          },
          onApprove: async (data, actions) => {
            const details = await actions.order.capture();
            alert('Pago completado por ' + details.payer.name.given_name);
            
            try {
              await actualizarSesion(); // Asegúrate de que esta función devuelva una promesa
              setIsAutorizacionPago(false);
              setFormVisible(false);
              setIsMensajeCunsulta("Su pago se ha enviado");
              setIsMensajePago("Espere hasta que se autorice la pagina de consulta...");
              //navigate('/consultas-virtuales');
              //window.location.reload(); // Ajusta la ruta según tu configuración
            } catch (error) {
              console.error('Error al actualizar la sesión:', error);
              alert('Ocurrió un error al procesar tu pago.');
            }
          },
          onError: (err) => {
            console.error('Error en el pago: ', err);
            alert('Hubo un error al procesar el pago, por favor intenta de nuevo.');
          }
        }).render('#paypal-container');
      };
      document.head.appendChild(script);
    }
  }, [metodoPago, paypalClientId, Value]);
  
  const actualizarSesion = () => {
    return axios.post('https://medico24x7.com/api/actualizar_sesion.php', { 
      metodo_de_pago: 'Paypal o Tarjeta' // Puedes enviar información adicional aquí si es necesario
    }, { withCredentials: true }) // Asegúrate de que las opciones están aquí
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data.message);
          alert('Todo se envió correctamente');
        } else {
          console.error(response.data.message);
          alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
        }
      })
      .catch(error => {
        console.error('Error al actualizar la sesión:', error);
        alert('Ocurrió un error al procesar tu pago.');
      });
  };

  const handleCheckboxChange = (e) => {
    const estado = e.target.checked ? 'si' : 'no';
    setMostrarForm2(e.target.checked);
    axios.post('https://medico24x7.com/api/procesar.php', { aceptar: estado }, { withCredentials: true })
      .then(response => {
        // Puedes manejar la respuesta si es necesario
        console.log(response.data);
        setCheckboxVisible(false);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleMetodoPagoChange = (e) => {
    const metodo = e.target.value;
    setMetodoPago(metodo);
    //console.log(metodo);
    if (metodo === 'paypal') {
      // Obtener el client_id desde el servidor
      setLoadingPaypal(true);
      axios.get('https://medico24x7.com/api/obtener_paypal_config.php', { withCredentials: true })
        .then(response => {
          setPaypalClientId(response.data.client_id);
          setLoadingPaypal(false);
        })
        .catch(error => {
          console.error('Error al cargar los datos de PayPal:', error);
          alert('Hubo un problema al configurar PayPal, por favor intenta de nuevo.');
          setLoadingPaypal(false);
        });
    }
  };

  const handleTransferenciaSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('metodo_de_pago', 'Transferencia');

    axios.post('https://medico24x7.com/api/actualizar_sesion.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data.message);
          alert('Todo se envió correctamente');
          setIsAutorizacionPago(false);
          setFormVisible(false);
          setIsMensajeCunsulta("Su pago se ha enviado");
          setIsMensajePago("Espere hasta que se autorice la pagina de consulta...");
          //navigate('/consultas-virtuales');
          //window.location.reload(); // Ajusta la ruta según tu configuración
        } else {
          console.error(response.data.message);
          alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
        }
      })
      .catch(error => {
        console.error('Error al actualizar la sesión:', error);
        alert('Ocurrió un error al procesar tu pago.');
      });
  };

  const handleYappySubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('metodo_de_pago', 'Yappy');

    axios.post('https://medico24x7.com/api/actualizar_sesion.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data.message);
          alert('Todo se envió correctamente');
          setIsAutorizacionPago(false);
          setFormVisible(false);
          setIsMensajeCunsulta("Su pago se ha enviado");
          setIsMensajePago("Espere hasta que se autorice la pagina de consulta...");
          //navigate('/consultas-virtuales');
          //window.location.reload(); // Ajusta la ruta según tu configuración
        } else {
          console.log(response.data.message);
          alert('Error al actualizar la sesión. Por favor, intenta de nuevo.'+ response.data.message);
        }
      })
      .catch(error => {
        console.error('Error al actualizar la sesión:', error);
        alert('Ocurrió un error al procesar tu pago.');
      });
  };

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div>
      
      <header id="mainHeader">
        <div className="container">
          <img id="logo" src={`${process.env.PUBLIC_URL}/assets/images/LOGO_no_bg.png`} alt="LOGO#1" className="logo-svg" />
        </div>
      </header>

      <main id='pagos'>
        {/* Contenido de la página */}

        <div className="consulta-virtual">
        <p>Clasificamos las consultas virtuales en tres tipos según su grado de importancia:</p>
<ul>
  <li><strong>Tipo A</strong>: <u>Consultas de bajo riesgo</u>, ideales para revisiones de seguimiento, dudas generales o problemas de salud menores.</li>
  <li><strong>Tipo B</strong>: <u>Urgencias</u> que requieren atención médica oportuna, pero no representan un riesgo inmediato para la vida.</li>
  <li><strong>Tipo C</strong>: <u>Emergencias</u> que necesitan atención inmediata debido a un riesgo grave para la salud o la vida del paciente.</li>
</ul>
<p>Podemos atender consultas virtuales de tipo A y B. Sin embargo, si clasificamos la consulta como tipo C, no podremos atenderla virtualmente. En ese caso, le recomendamos al paciente acudir de inmediato al hospital, clínica o centro de salud más cercano.</p>
<br />
        <p><strong>Clasificación:</strong> {isMensajeCunsulta}</p>
        </div>


        {formVisible && (
          !isAutorizacionPago ? (
          <div className="cont-formulario">
        <p>{isMensajePago}</p>
      </div>
    ) : (
        <div className="cont-formulario">
          <h1>Área de Pagos</h1>
          <form id="Form">
            <fieldset>
              <legend>Acuerdo Entre Ambas Partes</legend>
              <p>Acuerdo...</p>
              <label htmlFor="acuerdo">
              {checkboxVisible && (
                <input type="checkbox" id="acuerdo" name="acuerdo" onChange={handleCheckboxChange} />
              )}
                 Estoy de acuerdo con los términos y condiciones
              </label>
            </fieldset>
          </form>

          {mostrarForm2 && (
            <form id="Form2" className="seleccionar">
              <fieldset>
                <legend>Selecciona un método de pago</legend>
                <select id="metodo-pago" value={metodoPago} onChange={handleMetodoPagoChange}>
                  <option value="">Seleccione...</option>
                  <option value="paypal">PayPal y Tarjetas Débito/Crédito</option>
                  <option value="transferencia">Transferencia Bancaria (Solo Panamá)</option>
                  <option value="yappy">Yappy (Solo Panamá)</option>
                </select>
              </fieldset>
            </form>
          )}

          {/* Formulario para PayPal */}
          {metodoPago === 'paypal' && (
            <div id="paypal-container" className="formulario">
              {loadingPaypal && <p>Cargando PayPal...</p>}
            </div>
          )}

          {/* Formulario para Transferencia Bancaria */}
          {metodoPago === 'transferencia' && (
            <form id="form-transferencia" className="formulario" onSubmit={handleTransferenciaSubmit}>
              <h3>Pago con Transferencia Bancaria</h3>
              <p>Por favor realiza la transferencia a una de estas cuentas:</p>
              <br />
              <p>Banco General: Enrique Puentes</p>
              <p>Cuenta Ahorros: 04-37-98-032556-7</p>
              <br />
              <p>Scotia Bank (Bank of Nova Scotia): Enrique Puentes Ruiz.</p>
              <p>Cuenta Ahorros: 200000122373</p>
              <br />
              <p>Por favor, no escriba nada en descripción o comentarios.</p>
              <fieldset>
                <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
                <input type="file" id="comprobante_tranferencia" name="comprobante_tranferencia" multiple required />
              </fieldset>
              <button type="submit">Confirmar Transferencia</button>
            </form>
          )}

          {/* Formulario para Yappy */}
          {metodoPago === 'yappy' && (
            <form id="form-yappy" className="formulario" onSubmit={handleYappySubmit}>
              <h3>Pago con Yappy</h3>
              <p>Por favor realiza la transferencia al siguiente número de celular:</p>
              <br />
              <p>Número de celular: 6475-3624 (Enrique Puentes).</p>
              <br />
              <p>Por favor, no escriba nada en descripción o comentarios.</p>
              <fieldset>
                <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
                <input type="file" id="comprobante_tranferencia" name="comprobante_tranferencia" multiple />
              </fieldset>
              <button type="submit">Confirmar Pago</button>
            </form>
          )}
        </div>
      
    ))}
    {!formVisible && (
            <div className="cont-formulario">
          <p>{isMensajePago}</p>
        </div>
          )}
      </main>
      {/* Asegúrate de trasladar el contenido de mobile_view.js a App.js o integrarlo de otra manera */}
    </div>
  );
}

export default App;