import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Usuarios/Recibo_pagos.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


//Conexión a Base de datos
const [consultas, setConsultas] = useState([]);

  useEffect(() => {
    // Obtener los datos de consultas desde el backend
    const fetchConsultas = async () => {
      try {
        const response = await axios.get("https://medico24x7.com/api/Usuarios/Recibo.php", { withCredentials: true }); // Ruta del backend
        setConsultas(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error al obtener las consultas:", error);
      }
    };

    fetchConsultas();
  }, []);

  const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/Usuarios/descargar_recibo.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
};

const handleDescargarArchivo = (id) => {
  const url = `https://medico24x7.com/api/Admin/descargar_archivo_fiscal.php?id=${id}`;
  window.open(url, '_blank');
};

//Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='body_r_pagos'>
        <main id='main_recibo'>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Cedula</th>
            <th>Correo Electrónico</th>
            <th>Celular</th>
            <th>Monto</th>
            <th>Acuerdo</th>
            <th>Metodo De Pago</th>
            <th>Descargar Comprobante</th>
            <th>Recibo Fiscal</th>
          </tr>
        </thead>
        <tbody>
  {Array.isArray(consultas) && consultas.length > 0 ? (
    consultas.map((consulta, index) => (
      <tr key={index}>
        <td>{consulta.nombre}</td>
        <td>{consulta.apellido}</td>
        <td>{consulta.cedula}</td>
        <td>{consulta.correo}</td>
        <td>{consulta.celular}</td>
        <td>{consulta.monto}</td>
        <td>{consulta.acuerdo}</td>
        <td>{consulta.metodo_de_pago}</td>
        <td>
          {consulta.comprobante_tranferencia_encrypted ? (
            <button onClick={() => handleDownload(consulta.id)}>
              Descargar
            </button>
          ) : (
            "No disponible"
          )}
        </td>
        <td>
          {consulta.archivo_nombre ? (
            <button onClick={() => handleDescargarArchivo(consulta.id)}>
              Descargar Archivo
            </button>
            ) : (
              "No disponible"
            )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9">No hay recibos disponibles</td>
    </tr>
  )}
</tbody>
      </table>
    </main>
    </div>
  );
}

export default App;