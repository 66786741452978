import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Admin/Login_Admin.css'; //  CSS

const Login_Admin = () => {
  const [email_admin, setEmail] = useState('');
  const [password_admin, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://medico24x7.com/api/Admin/login_admin.php', {
        email: email_admin,
        password: password_admin
      }, {
        withCredentials: true
      });

      if (response.data.success) {
        setMessage(response.data.message);
        //console.log(response.data.message)
        navigate('/Consultas_Admin');
        window.location.reload();
      } else {
        setMessage(response.data.message); 
        console.log(response.data)
      }
    } catch (error) {
      //console.error("Error en la solicitud:", error);
      setMessage("Hubo un error en el login.");
    }
  };

  return (
    <div className="login_admin_container">
      <main className="login_admin">
        <div className='login-section'>
          <h2>Inicio de Sesión Admin</h2>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email_admin}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={password_admin}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Iniciar Sesión</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </main>
    </div>
  );
};

export default Login_Admin;